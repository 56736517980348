<template>
  <div class="c-app flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="8">
          <CCardGroup>
            <CCard class="p-4">
              <CCardBody>
                <CForm>
                  <CRow>
                    <img
                    src="img/logo/logo.png"
                    style="height:70px;"
                    />
                  <img
                    src="img/logo/fraudavoid_ logo.png"
                    style="height:70px; width:180px;"
                  />
                  </CRow>
                  <h4 class="text-muted mt-2">Reset Your Password</h4>
                  <CInput
                  type="password"
                    placeholder="New Password"
                    autocomplete="password"
                    v-model="input.password"
                  >
                   <template #prepend-content><CIcon name="cil-lock-locked"/></template>
                  </CInput>

                    <CInput
                    type="password"
                    placeholder="Re-Enter Password"
                    autocomplete="password"
                    v-model="input.repassword"
                    >
                    <template #prepend-content><CIcon name="cil-lock-locked"/></template>
                    </CInput>
                  <CRow>
                    <CCol col="8" class="text-left">
                      <CButton color="primary" class="px-6" @click="MailPassword()">Reset Password</CButton>
                    </CCol>
                    <CCol col="8" class="text-right">
                      <CRow>
                      <CButton color="link" class="px-0" @click="gotoPage('login')">Remember Password? Login</CButton>
                      </CRow>
                      <CRow>
                      <CButton color="link" class="px-0 d-lg-none" @click="gotoPage('register')">Register now!</CButton>
                      </CRow>
                    </CCol>
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
            <CCard
              color="primary"
              text-color="white"
              class="text-center py-5 d-md-down-none"
              body-wrapper
            >
              <CCardBody>
                <h2>Sign up</h2>
                <img
                    src="img/logo/logo.png"
                    style=""
                  />
                
                <p>Glad to see you again
                While you were away fraudAvoid has been monitoring your traffic for fraudulent patterns and making sure you see if your revenues are at risk</p>
                <CButton
                  color="light"
                  variant="outline"
                  size="lg"
                   @click="gotoPage('register')"
                >
                  Register Now!
                </CButton>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import LoginService from './../../services/loginControlService'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
export default {
  name: 'Login',
  data() {
            return {
                input: {
                    password: "",
                    repassword:"",
                    token:''
                }
            }
        },
      mounted(){
          if(this.$route.query.tkn){
              this.input.token = this.$route.query.tkn
          }
          else{
              Swal.fire({title: 'Error!',text: "Please Click on URl from mail",icon: 'error',confirmButtonText: 'Close',position: 'top-end',showConfirmButton: false,timer: 1000})
              this.$router.push('login')
          }
      },
        methods: {
          gotoPage(txt){
            switch(txt){
              case 'register' : this.$router.push("/pages/register")
              break;
              case 'forgotpassword' : this.$router.push("/pages/forgotpassword")
              break;
              case 'login': this.$router.push("/pages/login")
              break
            }
           },
        validate(){
        if(this.input.password.length < 6){
          Swal.fire({title: 'Error!',text: "Please use minimum 6 digit password",icon: 'error',confirmButtonText: 'Close',position: 'top-end',showConfirmButton: false,timer: 1000})
            return false
          }
        else if(this.input.password != this.input.repassword){
            Swal.fire({title: 'Error!',text: "Password don't match.",icon: 'error',confirmButtonText: 'Close',position: 'top-end',showConfirmButton: false,timer: 1000})
            return false
          }
        else if(this.input.password.length >= 6 && this.input.password == this.input.repassword && this.input.password != '' ){
            return true
        }
        else{
          Swal.fire({title: 'Error!',text: "Please Enter all details",icon: 'error',confirmButtonText: 'Close',position: 'top-end',showConfirmButton: false,timer: 1000})
            return false
        }
      },
         async MailPassword() {
            try {
              if(this.validate()){
                // const urlParams = new URLSearchParams(queryString);
                let response
                try {
                    let payload = {
                        token:this.input.token,
                        password:this.input.password
                    }
                  response = await LoginService.changepwd(payload)
                } catch(e){
                  console.error("Error in login")
                  console.log(e)
                }
              if (response.result == true) {
                Swal.fire({title: 'Success!',text: "Successfully Password changed",icon: 'success',confirmButtonText: 'Close',position: 'top-end',showConfirmButton: false,timer: 1000})
                this.$router.push('Login')
              }
              else {
               Swal.fire({title: 'Error!',text: response.message,icon: 'error',confirmButtonText: 'Close',position: 'top-end',showConfirmButton: false,timer: 1000})
              }}
            }catch(e){
              // Swal.fire({title: 'Error!',text: "connection error'",icon: 'error',confirmButtonText: 'Close',position: 'top-end',showConfirmButton: false,timer: 1000})
              // this.$router.push('Login')
            }
            },
         
        }
}
</script>
